<template>
  <div id="DetailForm">
    <div class="row">
      <ProgressHeader :step="step" />
      <div class="content_area">
        <div class="form_modal">
          <div class="form_box">
            <div class="preview_header">
              <button
                @click="$store.commit('SetPreview', true)"
                class="preview_btn"
              >
                預覽
              </button>
            </div>
            <transition-group name="fade">
              <ImageEditor
                v-if="step == 0"
                v-model="form_data"
                ref="ImageEditor"
                key="ImageEditor"
                v-on:next-step="ChangeStep(1)"
              />
              <PayInfo
                v-if="step == 1"
                v-model="user_data"
                :programs="programs"
                ref="PayInfo"
                key="PayInfo"
                v-on:next-step="ChangeStep(1)"
              />
            </transition-group>
            <div class="form_footer">
              <button v-show="step > 0" @click="ChangeStep(0)">上一步</button>
              <button @click="ValidateForm()">{{ NextText }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SmoothScrollResister from '@/common/GSAP/SmoothScrollResister.js';
import ProgressHeader from '@/components/EasyForm/ProgressHeader';
import ImageEditor from '@/components/EasyForm/ImageEditor';
import PayInfo from '@/components/DetailForm/PayInfo';
import axios_plugin from '@/common/axios_plugin';
import { GetMetaData } from '@/common/meta';
export default {
  name: 'DetailForm',
  mixins: [SmoothScrollResister, axios_plugin],
  components: {
    ProgressHeader,
    ImageEditor,
    PayInfo,
  },
  data() {
    return {
      form_data: {
        page: [
          {
            name: '',
            title: '',
            info_1: '',
            info_2: '',
            color: '#000000',
            buttons: [
              {
                type: 'phone',
                title: '',
                value: '',
              },
              {
                type: 'email',
                title: '',
                value: '',
              },
              {
                type: 'address',
                title: '',
                value: '',
              },
              {
                type: 'website',
                title: '',
                value: '',
              },
            ],
            files: {
              cover: null,
              logo: null,
              card_1: null,
              card_2: null,
            },
          },
        ],
        template: 1,
        color: '#000000',
      },
      user_data: {
        name: '',
        phone: '',
        email: '',
        comment: '',
        program: 0,
        agree: false,
      },
      programs: null,
      step: 0,
      meta_data: null,
    };
  },
  methods: {
    ChangeStep(val) {
      if (val == 1) {
        this.step == 1
          ? this.SendFormData()
          : (this.step = this.step >= 1 ? 1 : this.step + 1);
      } else {
        this.step = this.step <= 0 ? 0 : this.step - 1;
      }
    },
    ValidateForm() {
      if (this.step == 0) {
        this.$refs.ImageEditor.Validate();
      } else if (this.step == 1) {
        this.$refs.PayInfo.Validate();
      }
    },
    async GetPrograms() {
      let result = await this.SendGetData(
        `${process.env.VUE_APP_BASE_API}/Programs/Read.php`
      );
      if (result != 'error') {
        this.programs = result.data;
        // console.log(result.data)
        this.user_data.program = this.$route.query.p
          ? this.$route.query.p
          : this.programs[0].id;
        this.meta_data = GetMetaData('填寫簡易資料', '', '');
        this.$nextTick(() => {
          window.prerenderReady = true;
        });
      }
    },
    async SendFormData() {
      let vm = this;
      let form_data = new FormData();
      let page_data = [];
      let page = vm.form_data.page[0];

      form_data.append('cover[]', page.files.cover);
      form_data.append('logo[]', page.files.logo);
      form_data.append('card[]', page.files.card_1);
      form_data.append('card[]', page.files.card_2);
      form_data.append('type', '簡易');
      let tmp_page = Object.assign(page);
      delete tmp_page.files;
      page_data.push(tmp_page);
      form_data.append('card_info', JSON.stringify(page_data));
      form_data.append('contact_info', JSON.stringify(vm.user_data));
      form_data.append('template', vm.form_data.template);
      form_data.append('color', vm.form_data.color);

      let result = await this.PostFormData(
        `${process.env.VUE_APP_BASE_API}/Orders/Create.php`,
        form_data
      );
      // console.log(result.data)
      if (result != 'error') {
        document.getElementById('DetailForm').innerHTML += result.data;
        document.getElementById('newebpay-form').submit();
      }
    },
  },
  computed: {
    NextText() {
      return this.step == 1 ? '送出' : '下一步';
    },
  },
  created() {
    this.GetPrograms();
  },
  mounted() {
    this.RegisterGsap('DetailForm');
  },
  metaInfo() {
    return this.meta_data;
  },
};
</script>
