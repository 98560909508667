<template src="./template.html"></template>

<script>
export default {
  name: "PayInfo",
  props: {
    value: {
      require: true,
    },
    programs: {
      require: true,
    },
  },
  data() {
    return {
      errors: [],
    };
  },
  watch: {
    value() {
      if (
        this.GetProgramType(this.value.program) != "y" &&
        this.value.payway != "reserve"
      ) {
        this.ChangePayway("reserve");
      }
    },
  },
  methods: {
    ChangeProgram(val) {
      let tmp_value = Object.assign({}, this.value);
      tmp_value.program = val;
      this.$emit("input", tmp_value);
    },
    ChangePayway(val) {
      let tmp_value = Object.assign({}, this.value);
      tmp_value.payway = val;
      this.$emit("input", tmp_value);
    },
    UpdateInfo(key, val) {
      let tmp_value = Object.assign({}, this.value);
      tmp_value[key] = val;
      this.$emit("input", tmp_value);
    },
    Validate() {
      this.errors = [];
      if (this.value["name"] == "") {
        this.errors.push({
          type: "name",
          content: "請物留空",
        });
      }
      if (this.value["phone"] == "") {
        this.errors.push({
          type: "phone",
          content: "請物留空",
        });
      }
      if (this.value["email"] == "") {
        this.errors.push({
          type: "email",
          content: "請物留空",
        });
      }
      if (this.value["agree"] == false) {
        this.errors.push({
          type: "agree",
          content: "請同意網站規範",
        });
      }

      if (this.errors.length <= 0) {
        this.$emit("next-step");
      }
    },
    GetError(key) {
      let error = this.errors.filter((item) => item.type == key);
      return error.length <= 0 ? "" : error[0].content;
    },
    GetProgramType(id) {
      let program = this.programs.filter((item) => item.id == id);
      return program[0].type;
    },
  },
  filters: {
    TypeConvert(val) {
      return val == "m" ? "月" : val == "y" ? "年" : "";
    },
  },
};
</script>