<template src="./template.html"></template>

<script>
import axios_plugin from "@/common/axios_plugin"
export default {
  name: "ImageEditor",
  mixins: [axios_plugin],
  props: {
    value: {
      require: true
    }
  },
  data() {
    return {
      errors: []
    }
  },
  methods: {
    async ChangeCover(file) {
      let filesize = ((file.size / 1024) / 1024).toFixed(4); // MB
      if (filesize >= 5) {
        this.$store.commit("SetDialog", { action: true, content: "檔案請勿超過5MB", title: "發生錯誤" })
      }
      else {
        let tmp_value = Object.assign({}, this.value)
        tmp_value.page[0].files.cover = await this.UploadImageData(file)
        this.$emit("input", tmp_value)
        if (file != null) {
          this.errors.forEach((item, item_index) => {
            if (item.type == "PersonalCover") {
              this.errors.splice(item_index, 1)
            }
          })
        }
      }
    },
    async ChangeLogo(file) {
      let filesize = ((file.size / 1024) / 1024).toFixed(4); // MB
      if (filesize >= 5) {
        this.$store.commit("SetDialog", { action: true, content: "檔案請勿超過5MB", title: "發生錯誤" })
      }
      else {
        let tmp_value = Object.assign({}, this.value)
        tmp_value.page[0].files.logo = await this.UploadImageData(file)
        this.$emit("input", tmp_value)
        if (file != null) {
          this.errors.forEach((item, item_index) => {
            if (item.type == "Logo") {
              this.errors.splice(item_index, 1)
            }
          })
        }
      }
    },
    async ChangeCardFront(file) {
      let filesize = ((file.size / 1024) / 1024).toFixed(4); // MB
      if (filesize >= 5) {
        this.$store.commit("SetDialog", { action: true, content: "檔案請勿超過5MB", title: "發生錯誤" })
      }
      else {
        let tmp_value = Object.assign({}, this.value)
        tmp_value.page[0].files.card_1 = await this.UploadImageData(file)
        this.$emit("input", tmp_value)
        if (file != null) {
          this.errors.forEach((item, item_index) => {
            if (item.type == "CardFront") {
              this.errors.splice(item_index, 1)
            }
          })
        }
      }
    },
    async ChangeCardBehind(file) {
      let filesize = ((file.size / 1024) / 1024).toFixed(4); // MB
      if (filesize >= 5) {
        this.$store.commit("SetDialog", { action: true, content: "檔案請勿超過5MB", title: "發生錯誤" })
      }
      else {
        let tmp_value = Object.assign({}, this.value)
        tmp_value.page[0].files.card_2 = await this.UploadImageData(file)
        this.$emit("input", tmp_value)
        if (file != null) {
          this.errors.forEach((item, item_index) => {
            if (item.type == "CardBehind") {
              this.errors.splice(item_index, 1)
            }
          })
        }
      }
    },
    Validate() {
      this.errors = []
      if (this.value.page[0].files.cover == null) {
        this.errors.push({
          type: "PersonalCover",
          content: "請上傳個人封面圖片"
        })
      }
      if (this.value.page[0].files.card_1 == null) {
        this.errors.push({
          type: "CardFront",
          content: "請上傳名片正面"
        })
      }
      if (this.value.page[0].files.card_2 == null) {
        this.errors.push({
          type: "CardBehind",
          content: "請上傳名片背面"
        })
      }
      if (this.value.page[0].files.logo == null) {
        this.errors.push({
          type: "Logo",
          content: "請上傳LOGO圖片"
        })
      }
      if (this.errors.length <= 0) {
        this.$emit("next-step")
      }
    },
    GetError(key) {
      let error = this.errors.filter(item => item.type == key)
      return error.length > 0 ? error[0].content : ""
    },
    async UploadImageData(files) {
      let formData = new FormData();
      formData.append("file", files);
      let result = await this.PostFormData(process.env.VUE_APP_BASE_API + "image_upload.php", formData)
      if (result != "error") {
        return result.data
      }
    }
  }
}
</script>